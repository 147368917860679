import "./components/App.css";
import Footer from "./components/Footer.jsx";
import Header from "./components/Header.jsx";
import Postmaster from "./components/Postmaster.jsx";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { Suspense } from "react";

function Page() {

  return (
    <div className="App">
      <div className="major-wrapper">
        <div className="main-wrapper flex">
          <Header/>
            <div className="page">
              <Postmaster />
            </div>
          </div>
          <Footer />
        </div>
      </div>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
    </Suspense>
  );
}
