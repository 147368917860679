import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export default function SpamProtection() {
  const { t } = useTranslation(["spamProtection", "common"]);

  useEffect(() => {
    document.title = t("spamprot") + " | Postmaster | Bluewin";
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="cortana-side-nav col-md-4 col-lg-3 hidden-phone-down">
          <div className="margin-v-4 sticky">
            <h2 className="sc-navy">{t("common:content")}</h2>
            <ul className="list">
              <ul>
                <li>
                  <Link to="#spam-policy" onClick={scrollToSpam}>
                    {t("content1")}
                  </Link>
                </li>
                <li>
                  <Link to="#authentication" onClick={scrollToAuth}>
                    {t("content2")}
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9">
          <h1 className="sc-navy margin-v-4">{t("spamprot")}</h1>
          <h2 id="spam-policy" className="sc-navy">
            {t("content1")}
          </h2>
          <p>{t("par1")}</p>

          <h3 id="how-does-spam-protection-work-at-bluewin" className="sc-navy">
            {t("how")}
          </h3>

          <p>{t("par2")}</p>

          <p>{t("par3")}</p>

          <ul className="list margin-top-2">
            <li>
              <Trans i18nKey="li1" t={t}>
                The <Link to="/law">legal framework</Link> must be respected in
                all circumstances.
              </Trans>
            </li>
            <p></p>

            <p></p>
            <li>{t("li2")}</li>
            <p></p>

            <p></p>
            <li>
              <Trans i18nKey="li3" t={t}>
                The sending of bulk e-mails or promotional e-mails may only be
                sent to addressees whose consent has been explicitly given (
                <Link to="/best-practices#opt-in-to-verify-email-address">
                  opt-in method
                </Link>
                ).
              </Trans>
            </li>
            <p></p>

            <p></p>
            <li>
              <Trans i18nKey="li4" t={t}>
                The recipient must be able to withdraw his consent without
                obstacles (
                <Link to="/best-practices#opt-out">opt-out method</Link>).
              </Trans>
            </li>
            <p></p>

            <p></p>
            <li>{t("li5")}</li>
            <p></p>

            <p></p>
            <li>{t("li6")}</li>
            <p></p>

            <p></p>
            <li>{t("li7")}</li>
            <p></p>

            <p></p>
            <li>{t("li8")}</li>
            <p></p>

            <p></p>
            <li>
              <Trans i18nKey="li9" t={t}>
                Senders must comply with the corresponding RFC standards such as{" "}
                <a href="https://tools.ietf.org/html/rfc5321">RFC 532</a>
                or <a href="https://tools.ietf.org/html/rfc5322">RFC 5322</a>.
              </Trans>
            </li>
            <p></p>

            <p></p>
            <li>{t("li10")}</li>
            <p></p>

            <p></p>
            <li>{t("li11")}</li>
            <p></p>

            <p></p>
            <li>{t("li12")}</li>
          </ul>
          <p></p>

          <p>{t("par4")}</p>

          <h2 id="authentication" className="sc-navy">
            {t("content2")}
          </h2>

          <p>{t("par5")}</p>

          <h3 id="spf" className="sc-navy">
            SPF
          </h3>

          <p>
            <Trans i18nKey="par6" t={t}>
              Publish an
              <a href={t("common:linkSPF")}>SPF</a>
              (Sender Policy Framework) record for your domains on the DNS zone.
              SPF is a technique designed to make it more difficult to
              counterfeit a sender. The recipient can therefore check whether
              the sending server has the rights to send via this domain.
            </Trans>
          </p>

          <h3 id="dkim" className="sc-navy">
            DKIM
          </h3>

          <p>
            <Trans i18nKey="par7" t={t}>
              Authenticate your mails with a
              <a href={t("common:linkDKIM")}>DKIM</a>
              (Domain Keys Identified Mail) signature. This signature is
              associated with your domain and is used for all outgoing e-mails.
              This technique, like SPF, is designed to prevent the sender of an
              e-mail from being forged.
            </Trans>
          </p>

          <h3 id="dmarc" className="sc-navy">
            DMARC
          </h3>

          <p>
            <Trans i18nKey="par8" t={t}>
              We highly recommend that you publish a
              <a href="https://dmarc.org/">DMARC</a> (Domain-based Message
              Authentication, Reporting and Conformance) record on your DNS.
              DMARC can be created in addition to an SPF or DKIM entry. With
              DMARC, rules can be defined on how the recipient should handle
              e-mails that do not have a valid SPF record or DKIM signature.
              Thus, it can be determined whether deviating e-mails should be
              quarantined or rejected directly. In addition, an e-mail address
              can be defined to which evaluations should be sent.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

function scrollToSpam() {
  const element = document.getElementById("spam-policy");
  element.scrollIntoView();
}

function scrollToAuth() {
  const element = document.getElementById("authentication");
  element.scrollIntoView();
}
