import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { useTranslation, Trans } from "react-i18next";

export default function TechnicalDetails() {
  const { t } = useTranslation(["technicalDetails", "common"]);

  useEffect(() => {
    document.title = t("tecdet") + " | Postmaster | Bluewin";
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="cortana-side-nav col-md-4 col-lg-3 hidden-phone-down">
          <div className="margin-v-4 sticky">
            <h2 className="sc-navy">{t("common:content")}</h2>
            <ul className="list">
              <ul>
                <li>
                  <Link to="#outbound-ip-addresses" onClick={scrollToOut}>
                    {t("content1")}
                  </Link>
                </li>
                <li>
                  <Link to="#error-codes" onClick={scrollToError}>
                    {t("content2")}
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9">
          <h1 className="sc-navy margin-v-4">{t("tecdet")}</h1>
          <h2 id="outbound-ip-addresses" className="sc-navy">
            {t("ip")}
          </h2>

          <ul className="list">
            <li>195.186.227.0/24</li>
            <li>195.186.120.0/24</li>
            <li>138.188.174.0/24</li>
            <li>138.188.175.0/24</li>
            <li>195.186.133.129</li>
            <li>195.186.198.141</li>
            <li>195.186.123.60</li>
            <li>195.186.136.60</li>

          </ul>

          <h2 id="error-codes" className="sc-navy">
            {t("content2")}
          </h2>

          <div className="table-generic">
            <div className="table__wrapper">
              <div className="row">
                <div className="col-xs-4 col-md-1">
                  <strong>ID</strong>
                </div>
                <div className="col-xs-4 col-md-1">
                  <strong>Code</strong>
                </div>
                <div className="col-xs-36 col-md-6">
                  <strong>{t("desc")}</strong>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc141</div>
                <div className="col-xs-4 col-md-1">550</div>
                <div className="col-xs-36 col-md-6">{t("par1")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc151</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">{t("par2")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc171</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">{t("par3")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc172 sc173 sc174</div>
                <div className="col-xs-4 col-md-1">550</div>
                <div className="col-xs-36 col-md-6">{t("par4")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">
                  sc204 sc205 sc206 sc207 sc208 sc209 sc962
                </div>
                <div className="col-xs-4 col-md-1">550 554</div>
                <div className="col-xs-36 col-md-6">{t("par5")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc771</div>
                <div className="col-xs-4 col-md-1">421 451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par6" t={t}>
                    A temporary send block has been triggered because your
                    sending behavior shows signs of typical spam sending. If you
                    feel this is an error, please contact the support of your
                    mail service provider. If you are sending yourself, please
                    use our
                    <ContactFormLink />
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc775</div>
                <div className="col-xs-4 col-md-1">421 451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par7" t={t}>
                    Your IP is listed on an internal blocklist. If you feel this
                    is an error, please contact the support of your mail service
                    provider. If you are sending yourself, please use our
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc821</div>
                <div className="col-xs-4 col-md-1">501</div>
                <div className="col-xs-36 col-md-6">{t("par8")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc822</div>
                <div className="col-xs-4 col-md-1">421</div>
                <div className="col-xs-36 col-md-6">{t("par9")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc823</div>
                <div className="col-xs-4 col-md-1">501</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par10" t={t}>
                    Your mail server registers with an invalid name. Please
                    check your server settings and try again later. For more
                    detailed information, please refer to
                    <a href="https://tools.ietf.org/html/rfc5321">RFC 5321</a>.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc824</div>
                <div className="col-xs-4 col-md-1">550</div>
                <div className="col-xs-36 col-md-6">{t("par11")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc831</div>
                <div className="col-xs-4 col-md-1">550</div>
                <div className="col-xs-36 col-md-6">{t("par12")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc832</div>
                <div className="col-xs-4 col-md-1">550</div>
                <div className="col-xs-36 col-md-6">{t("par13")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc921</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par14" t={t}>
                    The maximum number of e-mails sent to our platform has been
                    exceeded. This is a temporary block. Please try again later.
                    If you feel this is an error, please contact your mail
                    provider. If you are sending yourself, please use our
                    <ContactFormLink />. Please note that your IP addresses will
                    be judged by a reputation management. Therefore it is
                    important to warm up new IP addresses first before sending
                    large amounts of mail over them.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc922</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par15" t={t}>
                    Your sent e-mail was temporarily rejected due to a bad IP
                    reputation. The reason for this is that in the past a large
                    part of your mails were classified as spam. If you feel this
                    is an error, please contact your mail provider. If you are
                    sending yourself, please use our
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc941</div>
                <div className="col-xs-4 col-md-1">421</div>
                <div className="col-xs-36 col-md-6">
                  {t("par16")}
                  <Trans i18nKey="par16" t={t}></Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc942</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">{t("par17")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc943</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">{t("par18")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc944</div>
                <div className="col-xs-4 col-md-1">452</div>
                <div className="col-xs-36 col-md-6">{t("par19")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc951</div>
                <div className="col-xs-4 col-md-1">552</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par20" t={t}>
                    Your email was classified as a virus. If you find this to be
                    an error, please contact our support via
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc952</div>
                <div className="col-xs-4 col-md-1">451 552</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par21" t={t}>
                    Your message was classified as spam and therefore rejected.
                    Please try again later. If you find this to be an error,
                    please contact our support via
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">
                  sc961 sc974 sc976 sc977 sc978
                </div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par22" t={t}>
                    Your IP is listed on an internal blocklist. If you feel this
                    is an error, please contact the support of your mail service
                    provider. If you are sending yourself, please use our{" "}
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc963</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par23" t={t}>
                    Your sent email was temporarily rejected due to a bad IP
                    reputation. The reason for this is that in the past a large
                    part of your mails were classified as spam. If you feel this
                    is an error, please contact your mail provider. If you are a
                    provider, please use our <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc964</div>
                <div className="col-xs-4 col-md-1">451 554</div>
                <div className="col-xs-36 col-md-6">{t("par24")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc965 sc973</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par25" t={t}>
                    The maximum number of e-mails sent to our platform has been
                    exceeded. This is a temporary block. Please try again later.
                    If you feel this is an error, please contact your mail
                    provider. If you are sending yourself, please use our
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc966</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">{t("par26")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc967</div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">{t("par27")}</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc968</div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par28" t={t}>
                    Your message was classified as spam. If you find this to be
                    an error, please contact our support via
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc969</div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par29" t={t}>
                    Your message was rejected because you sent too many messages
                    classified as spam in a certain period of time. If you find
                    this to be an error, please contact our support via{" "}
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc971</div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">SPF hard fail</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc972</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">SPF soft fail</div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc975</div>
                <div className="col-xs-4 col-md-1">451</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par30" t={t}>
                    Your sent email was temporarily rejected. If you consider
                    this to be an error, please contact our support via
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc979</div>
                <div className="col-xs-4 col-md-1">554</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par31" t={t}>
                    Your message was rejected due to suspicious activity. If you
                    find this to be an error, please contact our support via{" "}
                    <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
              <div className="row">
                <div className="col-xs-4 col-md-1">sc42</div>
                <div className="col-xs-4 col-md-1">451 554</div>
                <div className="col-xs-36 col-md-6">
                  <Trans i18nKey="par32" t={t}>
                    The transmission of your message was rejected for internal
                    policy reasons. If you consider this to be an error, please
                    contact our support via <ContactFormLink />.
                  </Trans>
                </div>
              </div>
              <div className="border-horizontal"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class ContactFormLink extends React.Component {
  render() {
    return (
      <a href={i18n.t("common:linkContactForm")}>
        {i18n.t("common:contactForm")}
      </a>
    );
  }
}

function scrollToOut() {
  const element = document.getElementById("outbound-ip-addresses");
  element.scrollIntoView();
}

function scrollToError() {
  const element = document.getElementById("error-codes");
  element.scrollIntoView();
}
