import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React from "react";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { useTranslation } from "react-i18next";

export default function Cards() {
  const { t } = useTranslation(["cards", "common"]);

  return (
    <div id="card_div" className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("supptools")}</h2>
                <p className="card__text">{t("card1")}</p>
                <a href={t("common:linkSupportPage")} className="link">
                  {t("more")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("bestprac")}</h2>
                <p className="card__text">
                  {t("card2")}
                  <Link
                    to={"/" + i18n.language + "/best-practices"}
                    className="link"
                  >
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("protspam")}</h2>
                <p className="card__text">
                  {t("card3")}
                  <Link
                    to={"/" + i18n.language + "/spam-protection"}
                    className="link"
                  >
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("tecdetails")}</h2>
                <p className="card__text">{t("card4")}</p>
                <Link
                  to={"/" + i18n.language + "/technical-details"}
                  className="link"
                >
                  {t("more")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("faq")}</h2>
                <p className="card__text">
                  {t("card5")}
                  <Link to={"/" + i18n.language + "/faq"} className="link">
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__body">
                <h2 className="text-2 sc-navy">{t("law")}</h2>
                <p className="card__text">
                  {t("card6")}
                  <Link to={"/" + i18n.language + "/law"} className="link">
                    {t("more")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
