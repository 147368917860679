import App from "./App";
import {
  defineCustomElements,
  applyPolyfills,
} from "@swisscom/sdx/dist/js/webcomponents/loader";
import React from "react";
import ReactDOM from "react-dom";
import "@swisscom/sdx/dist/css/sdx.css";

import "./i18n.js";

require("es6-promise").polyfill();

ReactDOM.render(<App />, document.getElementById("root"));

applyPolyfills()
  .then(() => {
    defineCustomElements();
  })
  .catch((err) => {
    console.error("Error while applying Polyfills: " + err);
  });
