import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <footer className="footer footer--compact">
          <div className="container">
            <a className="logo" href="https://www.swisscom.ch/de/privatkunden">
              <span className="logo-lifeform" aria-label="Swisscom Logo"></span>
              <span className="logo-type"></span>
            </a>
            <ul
              className="nav-items nav-items--divider"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link "
                  href="https://www.swisscom.ch/de/privatkunden/impressum.html"
                  data-di-id="di-id-8e9db053-10369b0f"
                >
                  Impressum
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
  }
}
export default Footer;
