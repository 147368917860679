import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export default function BestPractices() {
  const { t } = useTranslation(["bestPractices", "common"]);

  useEffect(() => {
    document.title = t("bestprac") + " | Postmaster | Bluewin";
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="cortana-side-nav col-md-4 col-lg-3 hidden-phone-down">
          <div className="margin-v-4 sticky">
            <h2 className="sc-navy">{t("common:content")}</h2>
            <ul className="list">
              <ul>
                <li>
                  <Link
                    to="#info-for-operators-of-submitting-services"
                    onClick={scrollToInfo}
                    className="text-standard"
                  >
                    {t("content1")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#info-for-senders-of-mass-e-mails"
                    onClick={scrollToInfoSender}
                  >
                    {t("content2")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#legal-mandatory-requirements"
                    onClick={scrollToLaw}
                  >
                    {t("content3")}
                  </Link>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-md-8 col-lg-9">
          <h1 className="sc-navy margin-v-4">{t("bestprac")}</h1>
          <h2
            id="info-for-operators-of-submitting-services"
            className="sc-navy"
          >
            {t("info")}
          </h2>

          <h3 id="orient-on-m3aawg" className="sc-navy">
            {t("orient")}
          </h3>

          <p>
            <Trans i18nKey="par1" t={t}>
              <a href="https://www.m3aawg.org/"></a> (Messaging, Malware and
              Mobile Anti-Abuse Working Group) is the worlds largest association
              for combating online abuse. M3AAWGs recommendations are up-to-date
              industry standards and are rated as
              <a href="https://www.m3aawg.org/published-documents/">
                best practices
              </a>
              in the email community.
            </Trans>
          </p>

          <h3 id="no-reception-of-dynamic-emails" className="sc-navy">
            {t("con")}
          </h3>

          <p>{t("par2")}</p>

          <h3 id="authenticate-with-spf-dkim-and-dmarc" className="sc-navy">
            {t("auth")}
          </h3>

          <p>
            <Trans i18nKey="par3" t={t}>
              To ensure the validity and origin of your domains, we recommend
              that you authenticate with <a href={t("common:linkSPF")}>SPF</a>
              and/or <a href={t("common:linkDKIM")}>DKIM</a> and have a
              <a href="https://dmarc.org/">DMARC</a> record on your DNS.
            </Trans>
          </p>
          <p>{t("par4")}</p>

          <h3 id="block-listing" className="sc-navy">
            {t("block")}
          </h3>

          <p>
            <Trans i18nKey="par5" t={t}>
              To protect our customers from misuse we use various real time
              blocklists (RBLs). If one of your domains or IPs is blocklisted,
              you can check under
              <a href="https://mxtoolbox.com/blacklists.aspx">
                https://mxtoolbox.com/blacklists.aspx
              </a>
              . If you are listed, submit an application for the removal of the
              entry to the respective RBL service provider and respect the
              guidelines on content, sending behavior and technical requirements
              for future e-mails.
            </Trans>
          </p>

          <h3 id="secure-server-against-misuse" className="sc-navy">
            {t("secure")}
          </h3>

          <p>{t("par6")}</p>

          <h3 id="warm-up-new-ips" className="sc-navy">
            {t("warm")}
          </h3>

          <p>{t("par7")}</p>

          <h2 id="info-for-senders-of-mass-e-mails" className="sc-navy">
            {t("info2")}
          </h2>

          <h3 id="distribution-list" className="sc-navy">
            {t("dist")}
          </h3>

          <p>{t("par8")}</p>

          <h3 id="subject" className="sc-navy">
            {t("re")}
          </h3>

          <p>{t("par9")}</p>

          <h3 id="elements-in-newsletter-and-mass-emails" className="sc-navy">
            {t("elem")}
          </h3>

          <p>{t("par10")}</p>

          <h3 id="opt-in-to-verify-email-address" className="sc-navy">
            {t("verify")}
          </h3>

          <p>
            <Trans i18nKey="par11" t={t}>
              To ensure that a customer has actively signed up for your e-mail
              newsletter or bulk e-mail, it is helpful to apply an opt-in
              method. In order to obtain clear consent, we recommend the
              <a href={t("linkMailchimp")}>double-opt-in</a>
              method, where you have the e-mail address verified by the user.
            </Trans>
          </p>

          <h3 id="interval" className="sc-navy">
            {t("interval")}
          </h3>

          <p>{t("par12")}</p>

          <h3
            id="seperate-newsletter-and-administrative-emails"
            className="sc-navy"
          >
            {t("news")}
          </h3>

          <p>{t("par13")}</p>

          <h3 id="certifications" className="sc-navy">
            {t("cert")}
          </h3>

          <p>
            <Trans i18nKey="par14" t={t}>
              We recommend a certification by industry recognized companies and
              committees like
              <a href={t("common:linkReturnPath")}>Return Path</a>
              or <a href={t("common:linkCertifiedSenders")}>CSA</a>. The purpose
              of this certification is to ensure that your domains will be
              written on a comprehensive wantlist and that your e-mails are
              delivered to your inboxes with a higher probability.
            </Trans>
          </p>

          <h2 id="legal-mandatory-requirements" className="sc-navy">
            {t("law")}
          </h2>

          <h3 id="impressum" className="sc-navy">
            {t("impressum")}
          </h3>

          <p>{t("par15")}</p>

          <h3 id="opt-out" className="sc-navy">
            {t("opt")}
          </h3>

          <p>
            <Trans i18nKey="par16" t={t}>
              Provide a highly visible way for a recipient to unsubscribe from
              your newsletters or promotional e-mails (
              <a href={t("linkOptOut")}>opt-out-method</a>
              ). Ensure unsubscriber addresses are removed promptly from your
              distribution lists.
            </Trans>
          </p>
        </div>
      </div>
    </div>
  );
}

function scrollToInfoSender() {
  const element = document.getElementById("info-for-senders-of-mass-e-mails");
  element.scrollIntoView();
}

function scrollToInfo() {
  const element = document.getElementById(
    "info-for-operators-of-submitting-services"
  );
  element.scrollIntoView();
}

function scrollToLaw() {
  const element = document.getElementById("legal-mandatory-requirements");
  element.scrollIntoView();
}
