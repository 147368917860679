import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, useParams } from "react-router-dom";
import BestPractices from "./BestPractices.jsx";
import SpamProtection from "./SpamProtection.jsx";
import TechnicalDetails from "./TechnicalDetails.jsx";
import FAQ from "./FAQ.jsx";
import Law from "./Law.jsx";
import Cards from "./Cards.jsx";
import NotFound from "./NotFound.jsx";
import i18n from "../i18n";
import { SUPPORTED_LANGUAGES } from "../helpers/constants";
import ErrorBoundary from "./ErrorBoundary";

export default function Postmaster() {
  return (
    <div className="margin-v-4">
      <ErrorBoundary>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Cards />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/:lang/*" element={<LanguageWrapper />} />
            <Route path="/best-practices" element={<BestPractices />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/technical-details" element={<TechnicalDetails />} />
            <Route path="/law" element={<Law />} />
            <Route path="/spam-protection" element={<SpamProtection />} />
          </Routes>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

function LanguageWrapper() {
  const { lang } = useParams();

  const language = lang.toLowerCase();
  const languageParamIsValid = SUPPORTED_LANGUAGES.includes(language);

  // Set Language based on the URL (postmaster.bluewin.ch/en/something) -> Language set to en
  useEffect(() => {
    async function setLanguage() {
      await i18n.changeLanguage(language);
    }
    if (languageParamIsValid) {
      setLanguage();
    }
  }, []);

  return (
    <Routes>
      {
        // To differentiate /not-found-url and /en
        languageParamIsValid ? (
          <Route path="/">
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Cards />} />
            <Route path="/best-practices" element={<BestPractices />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/technical-details" element={<TechnicalDetails />} />
            <Route path="/law" element={<Law />} />
            <Route path="/spam-protection" element={<SpamProtection />} />
          </Route>
        ) : (
          <Route path="*" element={<NotFound />} />
        )
      }
    </Routes>
  );
}
