import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function FAQ() {
  const { t } = useTranslation("faqs");

  useEffect(() => {
    document.title = t("faq_title") + " | Postmaster | Bluewin";
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-8 col-lg-9">
          <h1 className="sc-navy margin-v-4">{t("faq_title")}</h1>
          <h2 id="why-are-my-emails-delayed" className="text-h3 sc-navy">
            {t("delay")}
          </h2>

          <p>{t("par1")}</p>

          <p>{t("par2")}</p>

          <p></p>
          <ul className="list">
            <li>{t("li1")}</li>
            <p></p>

            <p></p>
            <li>{t("li2")}</li>
          </ul>
          <p></p>

          <h2 id="why-are-my-emails-in-spam" className="text-h3 sc-navy">
            {t("rec")}
          </h2>

          <p>
            <Trans i18nKey="par3" t={t}>
              Bluewin aims to provide Bluewin users with optimal protection
              against fraudulent and unwanted e-mails. Such e-mails will be
              automatically intercepted by the spam filter. The reasons for this
              include: unwanted content in the e-mail, poorly maintained
              distribution lists, or spam messages from users. If you send bulk
              e-mails or newsletters, follow the
              <Link to="/best-practices">
                recommendations for bulk e-mail senders and newsletters
              </Link>
              so that your emails or newsletters are not marked as spam.
            </Trans>
          </p>

          <h2
            id="how-to-prevent-emails-frombeing-flagged-as-spam"
            className="text-h3 sc-navy"
          >
            {t("eval")}
          </h2>

          <p>
            <Trans i18nKey="par4" t={t}>
              To maximize the likelihood that your emails will not be rated as
              spam, we ll be happy to refer you to our
              <Link to="/best-practices">best practices</Link> and
              <a href="https://www.m3aawg.org/">M3AAWG</a>recommendations.
            </Trans>
          </p>

          <h2 id="how-to-get-on-wantlist" className="text-h3 sc-navy">
            {t("want")}
          </h2>

          <p>
            <Trans i18nKey="par5" t={t}>
              Bluewin does not offer a wantlist. We follow the philosophy that
              every e-mail participant adheres to the
              <Link to="/law">legal framework</Link> and behaves responsibly
              towards the market participants.There are certification options
              through industry recognized companies and committees like
              <a href={t("common:linkReturnPath")}>Return Path</a>
              or <a href={t("common:linkCertifiedSenders")}>CSA</a>. These
              precautions reduce the likelihood that your emails will be marked
              as spam.
            </Trans>
          </p>

          <h2
            id="why-is-a-connection-to-bluewin-systems-not-allowed"
            className="text-h3 sc-navy"
          >
            {t("sys")}
          </h2>

          <p>{t("par6")}</p>

          <h2
            id="how-does-bluewin-rate-the-reputation-of-an-email-sender"
            className="text-h3 sc-navy"
          >
            {t("rep1")}
          </h2>

          <p>{t("par7")}</p>

          <p></p>
          <ul className="list">
            <li>{t("li3")}</li>
            <p></p>

            <p></p>
            <li>{t("li4")}</li>
          </ul>
          <p></p>

          <h2
            id="why-do-emails-get-flagged-as-spam-although-the-reputation-is-good"
            className="text-h3 sc-navy"
          >
            {t("spam")}
          </h2>

          <p>{t("par8")}</p>

          <h2
            id="what-is-the-max-size-of-a-message"
            className="text-h3 sc-navy"
          >
            {t("size")}
          </h2>

          <p>{t("par9")}</p>
        </div>
      </div>
    </div>
  );
}
