import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector.jsx";

function Header() {
  const [showMenu, setShowMenu] = useState(false);
  function changeShowMenu(newValue) {
    setShowMenu(newValue);
  }

  useEffect(() => {}, [showMenu]);

  const { i18n } = useTranslation();
  return (
    <div className="Header">
      <div className="major-wrapper ">
        <div className="nav" data-init="auto">
          <nav className="nav__level0" role="navigation">
            <div className="nav__mainnav">
              <div className="container">
                <div className="nav__primary">
                  <ul className="nav-items">
                    <li className="nav-item">
                      <a
                        className="nav-link nav-link--active"
                        data-di-id="di-id-97515455-da28178d"
                        href="/"
                      >
                        <span className="text">Postmaster</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-link--header"
                        data-di-id="di-id-97515455-da28178d"
                        href="https://support.bluewin.ch/"
                      >
                        <span className="text">Delisting</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="nav__secondary">
                  <span className="sr-only">Secondary Navigation</span>
                  <ul className="nav-items">
                    <li className="nav-item hidden-md-down">
                      <a
                        className="nav-link"
                        href="https://www.swisscom.ch/de/privatkunden.html"
                        data-di-id="di-id-da80e038-50c29f46"
                      >
                        <i
                          className="icon icon-136-globe"
                          aria-hidden="true"
                        ></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item hidden-md-down">
                      <a
                        className="nav-link"
                        href="https://webmail.bluewin.ch"
                        data-di-id="di-id-3bbbca58-3061ac9"
                      >
                        <i
                          className="icon icon-051-message"
                          aria-hidden="true"
                        ></i>
                        Webmail
                      </a>
                    </li>
                    <li className="nav-item hidden-md-down">
                      <i aria-hidden="true"></i>
                      <LanguageSelector i18n={i18n} />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
          <nav className="nav__level1" role="navigation">
            <div className="container">
              <a className="logo" href="/">
                <span
                  className="logo-lifeform"
                  aria-label="Swisscom Logo"
                ></span>
                <span className="logo-type">
                  <img src="/assets/swisscom-logo-type.svg" alt="Swisscom" />
                </span>
              </a>
              <div className="nav__mainnav">
                <div className="nav__secondary">
                  <ul className="nav-items">
                    <li className="nav-item">
                      {showMenu ? (
                        <a
                          className="nav-icon nav-hamburger is-active hidden-tablet-up"
                          aria-label="Menu"
                          onClick={() => changeShowMenu(false)}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>
                      ) : (
                        <a
                          className="nav-icon nav-hamburger hidden-tablet-up"
                          aria-label="Menu"
                          onClick={() => changeShowMenu(true)}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {showMenu ? (
              <div className="nav__mainnav hidden-tablet-down">
                <div className="nav__primary is-open">
                  <ul className="nav-items">
                    <li className="nav-item">
                      <a className="nav-link nav-link--header" href="/">
                        <span className="text">Postmaster</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link nav-link--header"
                        href="https://support.bluewin.ch/"
                      >
                        <span className="text">Delisting</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link nav-link--header">
                        <LanguageSelector i18n={i18n} />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://www.swisscom.ch/de/privatkunden.html"
                        data-di-id="di-id-da80e038-50c29f46"
                      >
                        <i
                          className="icon icon-136-globe margin-right-1"
                          aria-hidden="true"
                        ></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://webmail.bluewin.ch"
                        data-di-id="di-id-3bbbca58-3061ac9"
                      >
                        <i
                          className="icon icon-051-message margin-right-1"
                          aria-hidden="true"
                        ></i>
                        Webmail
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Header;
