import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Law() {
  const { t } = useTranslation("law");

  useEffect(() => {
    document.title = t("law") + " | Postmaster | Bluewin";
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-8 col-lg-9">
          <h1 className="sc-navy margin-v-4">{t("law")}</h1>
          <h2
            id="guidelines-for-mass-emails-and-newsletters"
            className="text-h3 sc-navy"
          >
            {t("news")}
          </h2>

          <p>{t("par1")}</p>

          <ul className="list">
            <li>{t("li1")}</li>
            <li>{t("li2")}</li>
          </ul>

          <h2 id="violations-can-be-sanctioned" className="text-h3 sc-navy">
            {t("violation")}
          </h2>

          <p>{t("par2")}</p>

          <h2 id="relation-to-products" className="text-h3 sc-navy">
            {t("relat")}
          </h2>

          <p>{t("par3")}</p>

          <h2 id="consent-of-recipients" className="text-h3 sc-navy">
            {t("accept")}
          </h2>

          <p>
            <Trans i18nKey="par4" t={t}>
              Senders of bulk e-mails and newsletters require the consent of
              each user of their distribution list for the receipt of
              advertising e-mails
              <Link to="/best-practices#opt-in-to-verify-email-address">
                opt in
              </Link>
              ).
            </Trans>
          </p>

          <h2
            id="opt-out-from-newsletters-and-mass-emails"
            className="text-h3 sc-navy"
          >
            {t("logout")}
          </h2>

          <p>
            <Trans i18nKey="par5" t={t}>
              Recipients must be able to revoke the consent given at any time
              and to be able to unsubscribe from receiving these e-mails free of
              charge and without difficulty
              <Link to="/best-practices#opt-out">opt-out method</Link>).
            </Trans>
          </p>

          <h2 id="contact-information-from-sender" className="text-h3 sc-navy">
            {t("contact")}
          </h2>

          <p>{t("par7")}</p>
        </div>
      </div>
    </div>
  );
}
