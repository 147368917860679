import React from "react";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.modalRef = React.createRef();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error);
    console.info(info);
    this.modalRef.open();

    // Logging to external service can be added here later on.
  }

  render() {
    if (this.state.hasError) {
      return (
        <sdx-dialog
          notification-type="alert"
          icon-name="icon-warning"
          label={this.props.t("error")}
          type="modal"
          ref={(el) => (this.modalRef = el)}
        >
          <sdx-dialog-content>
            <p className="text-align-center">
              {this.props.t("something-went-wrong")}
            </p>
          </sdx-dialog-content>
        </sdx-dialog>
      );
    }

    return this.props.children;
  }
}

export default withTranslation(["common"])(ErrorBoundary);