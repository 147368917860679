import React from "react";
import { withTranslation } from "react-i18next";

class LanguageSelector extends React.Component {
  constructor() {
    super();
    this.languages = [
      { abbreviation: "de", name: "Deutsch" },
      { abbreviation: "en", name: "English" },
      { abbreviation: "fr", name: "Français" },
      { abbreviation: "it", name: "Italiano" },
      { abbreviation: "chde", name: "Schwizerdütsch" },
    ];
  }

  render() {
    var currentLanguage = this.props.i18n.language || "en";

    return (
      <sdx-menu-flyout close-on-click="true" direction="bottom-left">
        <sdx-menu-flyout-toggle id="language-switch-flyout-toggle">
          <div className="sr-only">{this.props.t("languageSelection")}</div>
          <span
            className="menu-flyout-toggle-link"
            aria-label={currentLanguage}
            aria-expanded="false"
          >
            {currentLanguage.toUpperCase()}
            <i
              aria-hidden="true"
              className="icon icon-013-chevron-down icon--s1"
            ></i>
          </span>
        </sdx-menu-flyout-toggle>

        <sdx-menu-flyout-list class="margin-top-2">
          {this.languages.map((language) => {
            return (
              <sdx-menu-flyout-list-item
                key={language.abbreviation}
                onClick={() => {
                  this.props.i18n.changeLanguage(language.abbreviation);
                  // Needed to remove the focus/highlight of the clicked language in the flyout
                  // to prevent all languages from being marked as selected.
                  document
                    .getElementById("language-switch-flyout-toggle")
                    .doFocus();
                }}
              >
                <span>
                  {currentLanguage == language.abbreviation ? (
                    <strong>{language.name}</strong>
                  ) : (
                    language.name
                  )}
                </span>
              </sdx-menu-flyout-list-item>
            );
          })}
        </sdx-menu-flyout-list>
      </sdx-menu-flyout>
    );
  }
}
export default withTranslation("header")(LanguageSelector);
